<template>
  <div class="appointment-list">
    <div class="main">
      <div class="search">
        <div class="title">查询</div>
        <div class="">
          <van-cell title="预约日期" is-link :value="appointmentDateComputed" @click="appointmentDateClick" />
          <van-calendar v-model="calendarShow" @confirm="onConfirm" :show-confirm="false" />
          <van-cell title="楼栋" is-link :value="curBuilding" @click="buildingClick" />
          <van-action-sheet v-model="buildingShow" :actions="buildingList" close-on-click-action @select="onSelect" cancel-text="取消" />
        </div>
      </div>
      <div class="list">
        <van-list :immediate-check="false" v-model="listStatus.loading" :finished="listStatus.finished" finished-text="没有更多了" @load="onLoad">
          <div class="list-item" v-for="item in list" :key="item.id">
            <item-card :title="item.classroomName || '暂无课室名称'">
              <div class="content">
                <div class="content-item">地址: {{ item.fullAddress }}</div>
                <div class="content-item">课室面积: {{ item.acreage }}m²</div>
                <div class="content-item">容纳人数: {{ item.capacity }}</div>
                <div class="content-item">课室说明: {{ item.remark }}</div>
              </div>
              <template #footer>
                <div class="action">
                  <van-button class="detail-btn" type="info" size="small" round @click="appointmentClick(item.id)">预约</van-button>
                </div>
              </template>
            </item-card>
          </div>
        </van-list>
      </div>
      <van-empty description="暂无数据" v-if="!list.length" />
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/classroom/classroomList.less";
import { SUCCESS_CODE } from "@/apis/notification";
export default {
  name: "ClassroomAppointmentList",
  data() {
    return {
      appointmentDate: null,
      curBuilding: "全部",
      curBuildingId: null,
      calendarShow: false,
      buildingShow: false,
      buildingList: [{ id: "", name: "全部" }],
      listStatus: {
        loading: true,
        finished: false
      },
      list: [],
      page: {
        pageNumber: 0,
        pageSize: 10
      }
    };
  },
  computed: {
    appointmentDateComputed() {
      return this.appointmentDate == null ? "全部日期" : this.appointmentDate;
    }
  },
  methods: {
    async getClassroomList(sign = false) {
      let params = {
        date: this.appointmentDate,
        buildingId: this.curBuildingId,
        page: true,
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize
      };
      if (!params.buildingId) delete params.buildingId;
      let ret = await this.$api.getClassroomList({
        params
      });
      if (ret.code != SUCCESS_CODE) return;
      ret.data.forEach(item => {
        item.fullAddress = `${item.province}${item.city}${item.area}${item.address}${item.buildingName}${item.doorplateNumber}`;
      });
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    handlePagination(totalPages) {
      if (this.page.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.page.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    async getClassroomBuilding() {
      let ret = await this.$api.getClassroomBuilding();
      if (ret.code != SUCCESS_CODE) return;
      this.buildingList = this.buildingList.concat(ret.data);
    },
    appointmentDateClick() {
      this.calendarShow = true;
    },
    buildingClick() {
      this.buildingShow = true;
    },
    onConfirm(date) {
      this.calendarShow = false;
      this.appointmentDate = this.$moment(date).format("YYYY-MM-DD");
      this.getInitialData();
    },
    reset() { },
    onSelect(action) {
      this.curBuilding = action.name;
      this.curBuildingId = action.id;
      this.getInitialData();
    },
    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.page.pageNumber = 0;
      this.getClassroomList();
    },
    appointmentClick(id) {
      let url = this.$utils.getFormUrlByKey("classroomReserve", { classroomId: id });
      location.href = url;
    },
    onLoad() {
      this.getClassroomList(true);
    }
  },
  created() {
    this.getClassroomList();
    this.getClassroomBuilding();
  }
};
</script>

<style></style>
